body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

label {
  font-size: 1rem;
}

.header {
  display: flex;
  color: black;
  font-weight: 600;
  text-align: left;
  font-size: 1.75rem;
  margin-left: 1rem;
}

.header-layout {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  background-color: rgb(248 250 252);
  border-bottom: 2px solid black;
}

.header-layout > button {
  font-size: 1rem;
  padding: 0.5rem 2rem;
  margin-right: 1rem;
  font-size: 16px;
  border-radius: 5px;
  background: rgb(226 232 240);
  color: black;
  cursor: pointer;
}

.header-layout > button:hover {
  background: black;
  color: white;
}

.header > p {
  margin-left: 15px;
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
}

.header-logo {
  max-width: 50px;
}

.add-series-btn {
  display: flex;
  width: 100%;
  background-color: rgb(248 250 252);
  cursor: pointer;
  border-radius: 5px;
  font-weight: 600;
  border: 2px solid gray;
  padding: 0.75rem 0.25rem;
}

.add-series-btn:hover {
  border: 2px solid black;
}

.plus-icon {
  margin-left: 1rem;
  color: rgb(71 85 105);
  cursor: pointer;
  align-self: center;

}

.add-series-btn > span {
  margin-left: 10px;
}

.selected-pill {
  background-color: rgb(203 213 225);
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
  padding-left: 0.75rem;
  padding-right: 1.1rem;
  border-radius: 0.5rem;
  font-weight: 400;
  font-size: 0.85rem;
}

.modal {
  background-color: white;
  width: 70vw;
  z-index: 1;
  position: fixed;
  border: 1px solid rgb(203 213 225);
  top: 15%;
  bottom: 10%;
  left: 15%;
  right: 15%;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
}

.modal-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 2%;
  margin-right: 2%;
  padding: 10px 0;
}

.modal-header {
  font-size: 1em;
  font-weight: 500;
  color: rgb(71 85 105);
}

.x-icon {
  margin-left: auto;
  margin-top: 1%;
  cursor: pointer;
  color: rgb(71 85 105);
}

.modal-content {
  flex: 1;
  max-height: 30vh;
  overflow-y: auto;
  padding: 1% 2%;
  border-bottom: 2px solid gray;
}

.modal-footer {
  min-height: 12%;
  background: rgb(248 250 252);
  border-top: 1px solid rgb(203 213 225);
  border-bottom: 1px solid rgb(203 213 225);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1% 2%;
}

.modal-footer-elements {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.modal-selected-pills {
  display: flex;
  flex-wrap: wrap;
}

.modal-category-table {
  display: flex;
  margin-left: 5%;
  margin-right: 5%;
  justify-content: space-between;
  font-size: 1rem;
  font-weight: 600;
  border-bottom: 2px solid gray;
  padding-bottom: 0;
  margin-bottom: 0;
}

.modal-category-table > p {
  margin-bottom: 1%;
  color: rgb(71 85 105);
}

.modal-card {
  display: flex;
  justify-content: space-between;
  margin-left: 3%;
  margin-right: 3%;
  margin-top: none;
  
}

.modal-card-checkbox {
  display: flex;
  align-items: center;
  margin-top: 0.75rem;
  margin-bottom: 0;
}

.modal-card-checkbox > p {
  margin-top: 0;
  margin-bottom: 0;
}

.modal-card > p {
  margin-top: 0.75rem;
  margin-bottom: 0;
}


.level0-title {
  margin-left: 10px;
}

.level1-title {
  margin-left: 20px;
}

.level2-title {
  margin-left: 35px;
}

.level3-title {
  margin-left: 50px;
}

.level0-weight, .level0-title {
  font-size: 1rem;
  font-weight: 600;
}

.level1-weight, .level1-title {
  font-size: 0.9rem;
  font-weight: 500;
  color: rgb(51 65 85);
}

.level2-weight, .level2-title {
  font-size: 0.85rem;
  font-weight: 400;
  color: rgb(71 85 105);
}

.level3-weight, .level3-title {
  font-size: 0.75rem;
  font-weight: 300;
}

.item1 { grid-area: chart; }
.item2 { grid-area: y-axis; }
.item3 { grid-area: x-start; }
.item4 { grid-area: x-end; }
.item5 { grid-area: legend; }
.item6 { grid-area: type; }

.selection-menu { grid-area: menu;}

.selection-menu {
  margin-right: 0.5rem;
}

.top-row {
  display: flex;
  justify-content: space-between;
}

.option-btn, .option-btn-menu {
  font-size: 0.75rem;
  padding: 0.5rem 1rem;
  margin-right: 0.25rem;
  font-size: 12px;
  border-radius: 5px;
  background: rgb(226 232 240);
  color: black;
  cursor: pointer;
  margin-top: auto;
  margin-bottom: auto;
}

.option-btn:hover, .option-btn-menu:hover {
  background: black;
  color: white;
}

.menu-options {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.chart-type-selection {
  margin-bottom: 30px;
}

.generate-chart-btn {
  font-size: 1rem;
  padding: 1rem 2rem;
  font-size: 16px;
  border-radius: 5px;
  background: rgb(226 232 240);
  color: black;
  cursor: pointer;
  margin-top: 10px;
  border: 2px solid black;
  width: 100%;
}

.generate-chart-btn:hover {
  background: black;
  color: white;
}

.selection-view {
  display: grid;
  height: 92vh;
  width: 100%;
  grid-template-areas:
    'menu menu chart chart chart chart chart chart chart chart'
    'menu menu chart chart chart chart chart chart chart chart'
    'menu menu chart chart chart chart chart chart chart chart'
    'menu menu chart chart chart chart chart chart chart chart'
    'menu menu chart chart chart chart chart chart chart chart'
    'menu menu chart chart chart chart chart chart chart chart'
    'menu menu chart chart chart chart chart chart chart chart'
    'menu menu chart chart chart chart chart chart chart chart'
    'menu menu chart chart chart chart chart chart chart chart'
    'menu menu chart chart chart chart chart chart chart chart'
    'menu menu chart chart chart chart chart chart chart chart'
    'menu menu chart chart chart chart chart chart chart chart'
    'menu menu chart chart chart chart chart chart chart chart';
  grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  gap: 10px; /* Optional: Adjust the gap between grid items */
  /* background-color: #2196F3; */
  padding: 10px; /* Optional: Adjust the padding */
  box-sizing: border-box;
  align-self: center;
}

.no-options-view {
  display: grid;
  height: 92vh;
  width: 100%;
  grid-template-areas:
    'chart chart'
    'chart chart';
  grid-template-rows: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  /* background-color: #2196F3; */
  padding: 10px; /* Optional: Adjust the padding */
  box-sizing: border-box;
  align-self: center;
}

.no-options-view > .item1 {
    position: relative;
    grid-area: chart;
}

.option-btn {
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 1;
}

.series-view > div {
  background-color: rgba(255, 255, 255, 0.8);
  text-align: center;
  font-size: 30px;
}

.item2 {
  margin-top: 5.5vh;
}

.item3 {
  display: flex;
  align-items: center;
  gap: 10px;
}

.item3 > label {
  font-size: 16px;
}

.item4 {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: flex-end;
}

.item4 > label {
  font-size: 16px;
}

.item5 {
  margin-top: 5.5vh;
}

.item5 > button {
  display: flex;
  align-items: center;
}

.series-select-pending {
  border: 2px solid red;
}

.series-select-pending:hover {
  border: 2px solid black;
}

.item6 {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.item6 > p {
  padding: 0;
  margin: 0;
  font-size: 20px;
}

.item6 > div {
  flex-grow: 1;
}

.blank-chart {
  width: 100%;
  height: 100%;
  border: 1px solid gray;
  display: flex;
  justify-content: center;
  border-radius: 5px;

}

.blank-chart > h2 {
  color: gray;
  font-size: 4rem;
  font-style: italic;
  text-align: center;
  margin-top: auto;
  margin-bottom: auto;
}

.wfall-detail-title {
  font-weight: bold;
  font-size: 1.5rem;
}

.wfall-detail-subtitle {
  font-size: 1.15rem;
  font-style: italic;
  margin-top: 1rem;
}

.category-display-header {
  display: flex;
  justify-content: space-between;
  font-weight: bold;
}

.category-display-row {
  display: flex;
  justify-content: space-between;
}


.category-display-row > .level1-title {
  margin-left: 0px;
}

.category-display-row > .level2-title {
  margin-left: 10px;
  font-weight: 600;
  font-size: 0.9rem;
}

.category-display-row > .level3-title {
  margin-left: 25px;
}

.display-explain {
  font-size: 1.2rem;
  line-height: 1.5rem;
  font-style: italic;
}

.option-btn-mobile {
  display: none;
}


@media (max-width: 1280px) {

  .item1 { grid-area: chart; }
  .selection-menu { grid-area: menu;}


  .selection-view {
    display: grid;
    height: 100vh;
    width: 100%;
    grid-template-areas:
      'chart chart chart chart chart chart chart chart chart chart'
      'chart chart chart chart chart chart chart chart chart chart'
      'chart chart chart chart chart chart chart chart chart chart'
      'chart chart chart chart chart chart chart chart chart chart'
      'chart chart chart chart chart chart chart chart chart chart'
      'chart chart chart chart chart chart chart chart chart chart'
      'menu menu menu menu menu menu menu menu menu menu'
      'menu menu menu menu menu menu menu menu menu menu';
    grid-template-rows: repeat(1fr, 8);
    grid-template-columns: repeat(1fr, 10);
    gap: 5px;
    /* background-color: #2196F3; */
    padding: 10px;
    box-sizing: border-box;
    align-self: center;
}

.option-btn-menu {
  display: none;
}

.option-btn:hover, .option-btn-menu:hover {
  background: rgb(226 232 240);
  color: black;
}

.generateBtn {
  width: 100%;
  margin-bottom: 60px;
}

.blank-chart > h2 {
  color: gray;
  font-size: 1.5rem;
  font-style: italic;
  text-align: center;
  margin-top: auto;
  margin-bottom: auto;
}

.header {
  display: flex;
  color: black;
  font-weight: 600;
  text-align: left;
  font-size: 1.15rem;
  margin-left: 1rem;
}

.header-logo {
  max-width: 40px;
}

.menu-options {
  gap: 20px;
}

.chart-type-selection {
  margin-bottom: 20px;
}

.generate-chart-btn {
  width: 100%;
}

.item1 {
  /* position: relative; */
  grid-area: chart;
  width: 100%;
}
.option-btn {
  display: flex;
  font-size: 0.75rem;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  background: rgb(226 232 240);
  color: black;
  cursor: pointer;
}

.top-row > h3 {
  font-size: 1rem;
}

.custom-label text {
  font-size: 10px;
}

.wfall-detail-title {
  margin-top: 10px;
  margin-bottom: 5px;
  font-size: 20px;
}

.wfall-chart-title {
  text-align: center;
  margin-bottom: 5px;
  margin-top: 5px;
}

.wfall-chart-subtitle {
  text-align: center;
  margin-bottom: 10px;
  margin-top: 0;
  padding: 0;
  font-size: 12px;
  font-weight: 400;
  font-style: italic;
}

.category-display-row > .level1-title {
  margin-left: 0px;
}

.category-display-row > .level2-title {
  margin-left: 5px;
  font-weight: 600;
  font-size: 0.9rem;
}

.category-display-row > .level3-title {
  margin-left: 10px;
}

.option-btn {
  margin-bottom: 150px;
}

}